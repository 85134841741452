<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>管理收货地址</p>
      </div>
      <div class="headerR">
        <a href="javascript:;" @click="open">添加</a>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div
    :class="a.isEnabled == 1 ? 'addressbox on' : 'addressbox'"
    v-for="a in options.list"
    :key="a.id"
  >
    <div class="addressbox_1">
      <p class="p1">
        收货人：{{ a.name }}<span>{{ a.mobileNo }}</span>
      </p>
      <p class="p2">{{ a.province + a.city + a.region + a.address }}</p>
    </div>
    <div class="addressbox_2">
      <div class="ad1">
        <div class="ad1_1">
          <div
            v-if="a.isEnabled == 1"
            :class="a.isEnabled == 1 ? 'gwccheck on' : 'gwccheck'"
          ></div>
          <div
            v-else
            @click="enabled(a)"
            :class="a.isEnabled == 1 ? 'gwccheck on' : 'gwccheck'"
          ></div>
        </div>
        <div class="ad1_2">设为默认</div>
      </div>
      <div class="ad2" >
        <div class="ad2_1"><img src="images/del.png" /></div>
        <div class="ad2_2" @click="del(a)">删除</div>
      </div>
      <div class="ad2 mr">
        <a href="javascript:;" @click="edit(a)">
          <div class="ad2_1"><img src="images/edit.png" /></div>
          <div class="ad2_2">编辑</div>
        </a>
      </div>
    </div>
    <div class="w100" v-if="a.isEnabled == 1">
      <img src="images/addressline.png" />
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <van-popup
    v-model:show="options.isShow"
    round
    position="bottom"
    :style="{ height: '42%' }"
  >
    <div id="size">
      <div class="size1">
        <div class="size1_3" @click="options.isShow = false">
          <img src="images/close.png" />
        </div>
      </div>
    </div>
    <van-divider>地址管理</van-divider>
    <div class="addressDiv">
      <div class="addiv1">
        <div class="addiv1_l">收货人：</div>
        <div class="addiv1_r">
          <input v-model="options.item.name" type="text" />
        </div>
      </div>
      <div class="addiv1">
        <div class="addiv1_l">手机号：</div>
        <div class="addiv1_r">
          <input v-model="options.item.mobileNo" type="text" />
        </div>
      </div>
      <div class="addiv1" @click="options.isCityShow = true">
        <div class="addiv1_l">省市区：</div>
        <div class="addiv1_r">
          {{ options.item.province == "" ? "" : options.item.province + "/"
          }}{{ options.item.city == "" ? "" : options.item.city + "/"
          }}{{ options.item.region }}
        </div>
      </div>
      <div class="addiv1">
        <div class="addiv1_l">详细地址：</div>
        <div class="addiv1_r">
          <input v-model="options.item.address" type="text" />
        </div>
      </div>
    </div>
    <div class="size3">
      <a href="javascript:;" @click="save">保存</a>
    </div>
  </van-popup>
  <van-popup v-model:show="options.isCityShow" round position="bottom">
    <van-cascader
      v-model="options.cascaderValue"
      title="请选择所在地区"
      :options="options.citys"
      @close="options.isCityShow = false"
      @finish="onFinish"
    />
  </van-popup>
  <van-dialog />
</template>

<script>
import { onMounted, reactive } from "vue";
import addressApi from "@/api/addressApi";
import citysData from "@/json/citys.json";
import { Toast, Dialog } from "vant";
export default {
  setup() {
    const options = reactive({
      list: [],
      citys: [],
      item: {
        id: "",
        province: "",
        city: "",
        region: "",
        address: "",
        isEnabled: 0,
        name: "",
        postCode: "",
        mobileNo: "",
      },
      isShow: false,
      isCityShow: false,
      status: 200,
      message: "",
      cascaderValue: "",
    });
    onMounted(() => {
      options.citys = citysData;
    });
    const params = reactive({
      draw: 1,
      start: 0,
      length: 10,
    });
    function open() {
      options.item = {
        id: "",
        province: "",
        city: "",
        region: "",
        address: "",
        isEnabled: 0,
        name: "",
        postCode: "",
        mobileNo: "",
      };
      options.isShow = true;
    }
    function edit(item) {
      options.item = item;
      options.isShow = true;
    }
    function enabled(item) {
      Dialog.confirm({
        title: "设置默认地址",
        message:
          "亲，您确认要此收获地址设置为默认地址?",
      })
        .then(() => {
          // on confirm
          addressApi.enabled({ id: item.id }).then((res) => {
            if (res.status == 200) {
              search();
            } else {
              Toast.fail(res.message);
            }
          });
        })
    }

    function del(item){
      Dialog.confirm({
        title: "删除地址",
        message:
          "亲，您确认要删除收获人为【"+item.name+"】地址?",
      })
        .then(() => {
          // on confirm
          addressApi.delete({ id: item.id }).then((res) => {
            if (res.status == 200) {
              search();
            } else {
              Toast.fail(res.message);
            }
          });
        })
    }
    async function search() {
      await addressApi.search(params).then((res) => {
        if (res.status == 200) {
          options.list = res.data;
        }
      });
    }
    const onFinish = ({ selectedOptions }) => {
      options.isCityShow = false;
      var finishData = selectedOptions.map((option) => option.text);
      options.item.province = finishData[0];
      options.item.city = finishData[1];
      options.item.region = finishData[2];
      console.log(options.item.province);
    };
    async function save() {
      console.log(options.item.id);
      if (options.item.id == "") {
        console.log("add");
        await addressApi.add(options.item).then((res) => {
          options.item = res.result;
          options.status = res.status;
          options.message = res.message;
          options.isShow = false;
        });
      } else {
        console.log("modify");
        await addressApi.update(options.item).then((res) => {
          options.item = res.result;
          options.status = res.status;
          options.message = res.message;
          options.isShow = false;
        });
      }
      if (options.status == 200) {
        search();
      } else {
        Toast.fail(options.message);
      }
    }
    search();
    return {
      options,
      params,
      open,
      save,
      onFinish,
      edit,
      enabled,
      del
    };
  },
};
</script>

<style>
.addiv1_r {
  margin-top: 0.2rem;
  margin-left: 0.2rem;
}
#size {
  height: 0.3rem;
}
</style>