import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath;
const addressApi = {
    search: (params) => {
        return ajaxHandle(baseurl, 'post', '/oc/api/address/search', params)
    },
    getDefault:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/address/getDefault', params) 
    },
    add:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/address/add', params)  
    },
    update:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/address/update', params)
    },
    enabled:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/address/enabled', params)  
    },
    disable:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/address/disable', params)  
    },
    delete:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/address/delete', params)  
    }
}

export default addressApi